import {login} from '../../lib/api/rest/auth';
import Router from 'next/router';
import {useForm} from 'react-hook-form';
import React, {useContext, useState} from 'react';
import {UserContext} from '../../utils/context/appContext';
import InputForm from '../forms/inputForm';
import ContainedButton from '../buttons/containedButton';
import Link from 'next/link';
import {useTranslation} from 'next-i18next';

export default function LoginForm({routePath, isLoginPage = false}) {

  const {t: translateMenu} = useTranslation('menu');
  const {t: translateCommon} = useTranslation('common');

  const [user, setUser] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  const {
    register,
    handleSubmit,
    watch,
    getValues,
    clearErrors,
    setValue,
    formState: {errors},

  } = useForm();

  const formKeys = {
    IDENTIFIER: 'identifier',
    PASSWORD: 'password',
  };

  function handleLogin(event) {

    event.preventDefault();
    setLoading(true);
    const formValues = getValues();
    login(formValues[formKeys.IDENTIFIER], formValues[formKeys.PASSWORD]).then(
        async response => {

          const {role} = response.user;

          const _user = {
            ...user,
          };

          _user.isAuthenticated = true;
          _user.data = response.user;

          setUser(_user);

          if (isLoginPage) {
            const {description} = role;

            if (description.toLowerCase().includes('admin') ||
                description.toLowerCase().includes('partner')) {
              setLoading(false);
              await Router.push('/dashboard');

            } else {
              setLoading(false);
              await Router.push(routePath);
            }

          } else {
            setLoading(false);
            await Router.push(routePath);
          }

        },
    ).catch((error) => {

      console.error(error);

      setError(error.response.data);
      setLoading(false);

      const _user = {
        ...user,
      };

      user.isAuthenticated = false;
      _user.data = null;

      setUser(_user);
    });

  }

  return <>
    <form className="flex flex-col pt-1 gap-5 lg:pt-8"
          onSubmit={handleLogin}>
      <div className="flex flex-col pt-4">
        <InputForm watch={watch} register={register}
                   inputKey={formKeys.IDENTIFIER} errors={errors} type={'email'}
                   isRequired={true} placeholder={translateCommon('USER.EMAIL')}
                   showPlaceholderAsLabel/>

      </div>

      <div className="flex flex-col">
        <InputForm watch={watch} register={register}
                   inputKey={formKeys.PASSWORD} errors={errors}
                   type={'password'} isRequired={true}
                   placeholder={translateCommon('FORMS.PASSWORD')}
                   showPlaceholderAsLabel/>
      </div>

      <ContainedButton
          isLoading={loading}
          size={'sm'}
          light={true}
          disabled={loading}>
        {translateMenu('LOGIN')}
      </ContainedButton>
      <Link href={`/auth/forgot-password/`} passHref={true}>
        <a>
          <span
              className={'text-gray-700 text-sm underline hover:text-blue-700 transition-colors duration-500'}>{translateMenu(
              'FORGOT_PASSWORD')}</span>
        </a>
      </Link>

    </form>

    {!!error && <div className={'h-12 mt-5'}>

      {!!error &&
      error.message.map((error) => {
        return (
            <div
                key={error.messages[0].id}>
              <p className={'text-red-500 text-sm text-center'}>
                {error.messages[0].message}
              </p>
            </div>
        );
      })}
    </div>}
  </>;
}