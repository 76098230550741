import {useContext, useEffect, useState} from 'react';
import Link from 'next/link';

import {useRouter} from 'next/router';
import LoginForm from '../components/auth/loginForm';
import {checkUserAsync} from '../lib/api/rest/users';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import {useTranslation} from 'next-i18next';

export default function Login() {

  const {t: translatePages} = useTranslation('pages');

  const router = useRouter();

  useEffect(() => {

    if (!process.browser) return;

    checkUserAsync().then(async (response) => {

      const {type: _type} = response.role;
      const type = _type.toLowerCase();

      await router.push('/account');

    }).catch(async error => {

    });

  }, []);

  return <div className="w-full flex flex-wrap ">

    <div className="w-full lg:w-1/2 flex flex-col items-center min-h-screen">

      <div
          className="flex flex-col justify-center lg:justify-start my-auto pt-8 lg:pt-0 px-8 lg:px-24 max-w-2xl w-full">
        <p className="text-center text-3xl">{translatePages(
            'LOGIN.WELCOME_TITLE')}</p>
        <LoginForm routePath={'/account/'} isLoginPage={true}/>
      </div>

    </div>

    <div className="w-1/2 shadow-2xl">
      <img className="object-cover w-full h-screen hidden lg:block"
           src="https://amptech-spaces.fra1.digitaloceanspaces.com/strapi/fbd544e10d4cd9a5821289799df8aa0d"/>
    </div>
  </div>;

}

export async function getStaticProps({locale}) {

  return {
    props: {
      ...(await serverSideTranslations(locale,
          ['common', 'pages', 'products', 'site-meta', 'menu'])),
    },
  };
}